import { createContext, FC, PropsWithChildren, useContext } from "react";

type BaseSettingsContextBodyProps = {
  baseDomain: string;
  supportEmail: string;
  theme: null;
};

type BaseSettingsContextActionProps = {
  setTheme: () => void;
};

type BaseSettingsContextProps = BaseSettingsContextBodyProps & BaseSettingsContextActionProps;

const defaultBaseSettings: BaseSettingsContextBodyProps = {
  baseDomain: `revisioapp.com`,
  supportEmail: "support@revisioapp.com",
  theme: null,
};

const defaultActions: BaseSettingsContextActionProps = {
  setTheme: () => {},
};

const BaseSettingsContext = createContext<BaseSettingsContextProps>({ ...defaultBaseSettings, ...defaultActions });

const BaseSettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <BaseSettingsContext.Provider
      value={{
        ...defaultBaseSettings,
        ...defaultActions,
      }}
    >
      {children}
    </BaseSettingsContext.Provider>
  );
};

const useBaseSettings = () => useContext<BaseSettingsContextProps>(BaseSettingsContext);

export { BaseSettingsProvider, useBaseSettings };
