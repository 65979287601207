import { FC, useEffect } from "react";
import pluralize from "pluralize-esm";
import "./uploadIndicator.scss";
import { useUploadScreensProvider } from "@/components/app/UploadScreensProvider";

const UploadIndicator: FC = () => {
  const { queue } = useUploadScreensProvider();
  const queueLength = queue.length;
  return (
    <div className={`upload-indicator${queueLength > 0 ? " active" : ""}`}>
      <div className={`container`}>
        <div className={`process`}></div>
        <div className={`description`}>
          uploading {queueLength} {pluralize("file", queueLength)}
        </div>
      </div>
    </div>
  );
};

export { UploadIndicator };
