import { createContext, Dispatch, FC, PropsWithChildren, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";

type CurrentUserDataProps = {
  localTenantID: string | null;
};

type CurrentUserActionsProps = {
  setLocalTenantID: Dispatch<SetStateAction<string | null>>;
};

type CurrentUserProps = CurrentUserDataProps & CurrentUserActionsProps;
const defaultDataValue: CurrentUserDataProps = { localTenantID: null };

const defaultActionsValue: CurrentUserActionsProps = { setLocalTenantID: () => {} };

const Context = createContext<CurrentUserProps>({ ...defaultDataValue, ...defaultActionsValue });

const CurrentUserSettingsProvider: FC<PropsWithChildren<Partial<ReactNode>>> = ({ children }) => {
  const [localTenantID, setLocalTenantID] = useState<string | null>(defaultDataValue.localTenantID);
  useEffect(() => {}, []);

  return <Context.Provider value={{ localTenantID, setLocalTenantID }}>{children}</Context.Provider>;
};

const useCurrentUserSettingsProvider = () => useContext<CurrentUserProps>(Context);

export { CurrentUserSettingsProvider, useCurrentUserSettingsProvider };
