import { FC, memo } from "react";
import "./header.scss";
import Logo from "../logo";
import { HeaderTab } from "../header-tab/HeaderTab";
import { Avatar } from "../avatar/Avatar";
import { useAuth } from "@/components/app/AuthProvider";
import Menu from "@/components/menu";
import { NavLink, useNavigate } from "react-router-dom";
import { UploadIndicator } from "@/components/upload/UploadIndicator";

const Header: FC<HeaderProps> = memo(({ skin = "dark", isEditor = false }) => {
  const { me } = useAuth();
  const navigate = useNavigate();

  const avatarMenuOptions = [
    {
      label: "My Profile",
      action: () => {
        navigate(`profile`);
      },
    },
    {
      label: "Categories & Tags",
      action: () => {
        navigate(`tagging`);
      },
    },
    {
      label: "Log Out",
      action: () => {
        navigate(`logout`);
      },
    },
  ];

  return (
    <nav className={`header ${skin}`}>
      <div className={"menu-container"}>
        <NavLink to={"/"}>
          <Logo skin={`on-${skin}`} />
        </NavLink>
        {isEditor && <HeaderTab />}
      </div>
      <div className={"user-container"}>
        {isEditor && (
          <>
            <UploadIndicator />
            <Menu options={avatarMenuOptions}>
              <Avatar {...me} />
            </Menu>
          </>
        )}
      </div>
    </nav>
  );
});
export { Header };
