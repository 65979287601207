import { FC, lazy } from "react";
import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";

import { FullLayout } from "@/components/layout";
import { Error, Notfound, NotfoundInline } from "@/components/outcome";
import LoginRedirectPage from "./login/LoginRedirect";
import { GuestRoute, ProtectedRoute, SharedRoute } from "@/components/layout/Layout";
import { Pg } from "@/pages/pg/Pg";

const Projects = lazy<FC>(() => import("./projects"));
const Profile = lazy<FC>(() => import("./profile"));
const TaggingTage = lazy<FC>(() => import("./tagging"));
const TagsList = lazy<FC>(() => import("./tagging/tags"));
const CategoriesList = lazy<FC>(() => import("./tagging/categories"));
const Project = lazy<FC>(() => import("./project"));
const ScreenViewer = lazy<FC>(() => import("./screen-view"));
const ProjectCreationForm = lazy<FC>(() => import("./project-new"));
const Login = lazy<FC>(() => import("./login"));
const AssignTeam = lazy<FC>(() => import("./onboard/tenant-select/AssignTeam"));
const BaseDetails = lazy<FC>(() => import("./onboard/base-details/InputBaseDetails"));
const LogoutPage = lazy<FC>(() => import("./logout"));
const SignupPage = lazy<FC>(() => import("./signup"));

const protectedRoutes: RouteObject[] = [
  {
    path: "/",
    element: <ProtectedRoute />,
    errorElement: <Error />,
    children: [
      {
        path: "",
        element: <FullLayout />,
        errorElement: <Error />,
        children: [
          {
            path: "",
            element: <Navigate to="/projects" />,
          },
          {
            path: "projects",
            element: <Projects />,
          },
          {
            path: ":tenantSQID",
            element: <Navigate to="/projects" />,
          },
          {
            path: "tagging",
            element: <TaggingTage />,
            children: [
              {
                path: "",
                element: <Navigate to="tags" />,
              },
              {
                path: "tags",
                element: <TagsList />,
              },
              {
                path: "categories",
                element: <CategoriesList />,
              },
            ],
          },
          {
            path: ":tenantSQID/:projectSQID",
            element: <Project />,
            errorElement: <NotfoundInline />,
          },
        ],
      },
      {
        path: "new",
        element: <ProjectCreationForm />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: ":tenantSQID/:projectSQID/:screenSQID",
        element: <ScreenViewer />,
      },
      {
        path: "assign-team",
        element: <AssignTeam />,
      },
      {
        path: "acquaintance",
        element: <BaseDetails />,
      },
      {
        path: "logout",
        element: <LogoutPage />,
      },
    ],
  },
];
const sharedRoutes: RouteObject[] = [
  {
    path: "/",
    element: <SharedRoute />,
    errorElement: <Error />,
    children: [
      {
        path: ":tenantSQID/:projectSQID/view",
        element: <Project />,
      },
      {
        path: ":tenantSQID/:projectSQID/:screenSQID/view",
        element: <ScreenViewer />,
      },
    ],
  },
];
const guestRoutes: RouteObject[] = [
  {
    path: "/",
    element: <GuestRoute />,
    errorElement: <Error />,
    children: [
      {
        path: "login",
        children: [
          {
            path: "redirect",
            element: <LoginRedirectPage />,
          },
          {
            path: "",
            element: <Login />,
          },
        ],
      },
      {
        path: "signup",
        element: <SignupPage />,
      },
      {
        path: "pg",
        element: <Pg />,
      },
    ],
  },
  {
    path: "*",
    element: <Notfound />,
  },
];

const routes: RouteObject[] = protectedRoutes.concat(sharedRoutes).concat(guestRoutes);

const router = createBrowserRouter(routes);

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}
export default router;
