import { createContext, Dispatch, FC, PropsWithChildren, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { useAuth } from "@/components/app/AuthProvider";
import { useMyTenantQuery, useUpdateTenantTagsMutation, useUpdateTenantCategoriesMutation } from "@/graphql";

//null - we don't know tenant yet
//undefined - no tenant exists

export type TenantDataProps = {
  tenant: TenantFragment | null | undefined;
  availableTags: Array<TagProps>;
  availableCategories: Array<TagCategoryProps>;
  customCategories: {
    otherTags: string;
    projectType: string;
  };
  totalProjects: number;
};

export type TenantActionsProps = {
  refreshTenant: () => void;
  setTagAction: Dispatch<SetStateAction<ActionProps<TagProps>>>;
  setCategoryAction: Dispatch<SetStateAction<ActionProps<TagCategoryProps>>>;
};

export type TenantContextProps = TenantDataProps & TenantActionsProps;

function getAvailableTags(data: TenantFragment | null | undefined) {
  if (!data) return [];
  const availableTags: Array<TagProps> = [];
  data.attributes?.tags?.data.forEach((item) => {
    const tag: TagProps = {
      displayName: item.attributes?.displayName ?? "",
      id: item.id ?? "",
      isSystem: item.attributes?.isSystem ?? undefined,
      parent: {
        displayName: item.attributes?.tag_category?.data?.attributes?.displayName ?? "",
        type: item.attributes?.tag_category?.data?.attributes?.type ?? "multi_select",

        id: item.attributes?.tag_category?.data?.id ?? "0",
      },
    };
    availableTags.push(tag);
  });

  return availableTags;
}
function getAvailableCategories(data: TenantFragment | null | undefined) {
  if (!data) return [];
  const availableCategories: Array<TagCategoryBodyProps> = [];
  data.attributes?.tag_categories?.data.forEach((item) => {
    const filter: TagCategoryBodyProps = {
      displayName: item?.attributes?.displayName ?? "",
      id: item.id ?? "",
      type: item?.attributes?.type ?? "single_select",
      isSystem: item?.attributes?.isSystem,
    };
    availableCategories.push(filter);
  });
  return availableCategories;
}
function linkTagsVsCategories(categories: Array<TagCategoryBodyProps>, tags: Array<TagProps>) {
  return categories.map((category) => {
    const filteredTags = tags.filter((tag) => tag.parent.id === category.id);
    return { ...category, items: filteredTags };
  });
}
function countProjects(input: Array<{ id?: string | null | undefined }> | undefined): number {
  const ids = new Set(input?.map((value) => value.id));
  return ids.size;
}
function sanitizeCategories(categories: Array<TagCategoryProps>): Array<TagCategoryProps> {
  const systemCategories: Array<TagCategoryProps> = [];
  const specificCategories: Array<TagCategoryProps> = [];
  categories.forEach((item) => {
    item.isSystem ? systemCategories.push(item) : specificCategories.push(item);
  });

  return [
    ...specificCategories.sort((a, b) => (a.displayName > b.displayName ? 1 : -1)),
    ...systemCategories.sort((a, b) => (a.displayName > b.displayName ? 1 : -1)),
  ];
}
function sanitizeTags(tags: Array<TagProps>): Array<TagProps> {
  const sanitizedTags = tags.sort((a, b) => (a.parent.displayName > b.parent.displayName ? 1 : -1));
  return sanitizedTags;
}

const defaultDataValue: TenantDataProps = {
  tenant: null,
  availableCategories: [],
  customCategories: {
    otherTags: "",
    projectType: "",
  },
  availableTags: [],
  totalProjects: 0,
};

const defaultActionsValue: TenantActionsProps = {
  refreshTenant: async () => {},
  setTagAction: () => {},
  setCategoryAction: () => {},
};

type ActionProps<T> = {
  items: Array<T>;
  action: BaseAction;
} | null;

const Context = createContext<TenantContextProps>({ ...defaultDataValue, ...defaultActionsValue });

const TenantProvider: FC<PropsWithChildren<Partial<ReactNode>>> = ({ children }) => {
  const { me } = useAuth(); //Getting authentication
  const myTenantQuery = useMyTenantQuery({ skip: !me?.currentTenant.tenant, variables: { id: me?.currentTenant.tenant } }); //getting full Tenant Data
  const [sendUpdateTagsRequest, { loading: updateTagsRequestLoading, called: updateTagsRequestCalled, error: updateTagsRequestError }] =
    useUpdateTenantTagsMutation();
  const [sendUpdateCategoriesRequest, { loading: updateCategoriesRequestLoading, called: updateCategoriesRequestCalled, error: updateCategoriesRequestError }] =
    useUpdateTenantCategoriesMutation();
  const [tenantData, setTenantData] = useState<TenantDataProps>({ ...defaultDataValue });
  const [tagAction, setTagAction] = useState<ActionProps<TagProps>>(null);
  const [categoryAction, setCategoryAction] = useState<ActionProps<TagCategoryProps>>(null);

  function updateTenantLocally(data: TenantDataProps["tenant"]) {
    const availableTags = sanitizeTags(getAvailableTags(data));
    const availableCategories = linkTagsVsCategories(getAvailableCategories(data), availableTags);
    const enrichedCategories = sanitizeCategories([...availableCategories]);
    const projectTypeCustom = enrichedCategories.find((item) => item.displayName == "Project Type");
    const projectTypeCategoryID = projectTypeCustom?.id;
    const otherTagsCustom = enrichedCategories.find((item) => item.displayName == "Other Tags");
    const otherTagsCategoryID = otherTagsCustom?.id;
    const totalProjects = countProjects(data?.attributes?.projects?.data);
    setTenantData(() => ({
      tenant: myTenantQuery?.data?.tenant?.data,
      availableCategories: enrichedCategories,
      customCategories: {
        projectType: projectTypeCategoryID ?? "",
        otherTags: otherTagsCategoryID ?? "",
      },
      availableTags: availableTags,
      totalProjects: totalProjects,
    }));
  }

  useEffect(() => {
    if (!myTenantQuery.loading) {
      updateTenantLocally(myTenantQuery.data?.tenant?.data);
    }
  }, [myTenantQuery.data, myTenantQuery.loading]);

  // In case a new tag is added, it should be linked to the current Tenant

  useEffect(() => {
    if (tagAction && tagAction.action == "create" && tenantData.tenant?.id) {
      const newIds = tagAction.items.map((item) => item.id);
      const existingIds = tenantData.availableTags.map((item) => item.id);
      sendUpdateTagsRequest({ variables: { id: tenantData.tenant.id, input: [...existingIds, ...newIds] } }).then((result) => {
        updateTenantLocally(result.data?.updateTenant?.data);
      });
    }
    if (tagAction && tagAction.action == "update") {
      myTenantQuery.refetch().then((result) => {
        updateTenantLocally(result.data?.tenant?.data);
      });
    }
    if (tagAction && tagAction.action == "delete") {
      myTenantQuery.refetch().then((result) => {
        updateTenantLocally(result.data?.tenant?.data);
      });
    }
  }, [tagAction]);

  // In case a new category is added, it should be linked to the current Tenant

  useEffect(() => {
    if (categoryAction && categoryAction.action == "create" && tenantData.tenant?.id) {
      const newIds = categoryAction.items.map((item) => item.id);
      const existingIds = tenantData.availableCategories.map((item) => item.id);

      sendUpdateCategoriesRequest({ variables: { id: tenantData.tenant.id, input: [...existingIds, ...newIds] } }).then((result) => {
        updateTenantLocally(result.data?.updateTenant?.data);
      });
    }
    if (categoryAction && categoryAction.action == "update") {
      myTenantQuery.refetch().then((result) => {
        updateTenantLocally(result.data?.tenant?.data);
      });
    }
    if (categoryAction && categoryAction.action == "delete") {
      myTenantQuery.refetch().then((result) => {
        updateTenantLocally(result.data?.tenant?.data);
      });
    }
  }, [categoryAction]);

  return <Context.Provider value={{ ...tenantData, ...defaultActionsValue, setTagAction, setCategoryAction }}>{children}</Context.Provider>;
};

const useTenantProvider = () => useContext<TenantContextProps>(Context);

export { TenantProvider, useTenantProvider };
