import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import { useMeQuery } from "@/graphql";
import { getAuthToken } from "@/utils/auth";

//null - we don't know user's status yet (loading)
//undefined - user is not logged in
export type AuthDataProps =
  | Maybe<
      MeFragment & {
        currentTenant: AuthDataPermissions;
        availableTeams: Array<TeamBaseDetails>;
      }
    >
  | undefined;

type AuthActionsProps = {
  invalidate: () => void;
  refetch: () => void;
};

type AuthDataPermissions = {
  tenant: string | null;
  role: EnumTenantteamPermissions | null;
};

type AuthContextProps = { me: AuthDataProps; actions: AuthActionsProps };

const defaultDataValue: AuthDataProps = null;
const defaultActionsValue: AuthActionsProps = { invalidate: () => {}, refetch: () => {} };
const defaultValue: AuthContextProps = { me: defaultDataValue, actions: defaultActionsValue };

const Context = createContext<AuthContextProps>(defaultValue);

function checkOnboardingStatus(me: MeFragment): boolean {
  return !!me?.registeredAt;
}

function getAvailableTeamsFromMeQuery(me: MeFragment): Array<TeamBaseDetails> {
  const teams = me.tenant_teams?.data;
  if (!teams) return [];
  if (teams.length === 0) return [];
  const output: Array<TeamBaseDetails> = [];
  teams.forEach((item) => {
    if (
      item.id &&
      item.attributes?.permissions &&
      item.attributes.tenant?.data &&
      item.attributes.tenant?.data?.attributes?.domain &&
      item.attributes.tenant.data.id &&
      item.attributes.tenant.data.attributes?.companyName &&
      item.attributes.tenant.data.attributes?.slug
    ) {
      const newTeam: TeamBaseDetails = {
        id: item.id,
        name: `${item.attributes.permissions}s`,
        tenant: {
          name: item.attributes.tenant.data.attributes.companyName,
          id: item.attributes.tenant.data.id,
          domain: item.attributes.tenant.data.attributes.domain,
          slug: item.attributes.tenant.data.attributes.slug,
        },
        role: item.attributes.permissions,
        domain: item.attributes.tenant.data.attributes.domain,
      };
      output.push(newTeam);
    }
  });
  return output;
}

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const token = getAuthToken();
  const { data: userData, loading: userLoading, error: userError, refetch: userRefetch } = useMeQuery({ skip: !token });
  const [userAuthData, setUserAuthData] = useState<AuthDataProps>(defaultDataValue);
  const [currentTenant, setCurrentTenant] = useState<string>();

  const invalidateFunction = () => {
    setUserAuthData(null);
  };

  const refetchFunction = async () => {
    await userRefetch();
  };

  const defineDefaultTenant = (availableTeams: Array<TenantTeamFragment>, preferrableTenant: string | null | undefined): AuthDataPermissions => {
    const fallbackTenant: AuthDataPermissions = {
      tenant: null,
      role: "viewer",
    };
    if (availableTeams.length === 0) {
      return fallbackTenant;
    }

    const defaultTeam = availableTeams[0];
    const tenant = defaultTeam.attributes?.tenant?.data?.id;
    const role = defaultTeam.attributes?.permissions;
    if (tenant && role) {
      fallbackTenant.tenant = tenant;
      fallbackTenant.role = role;
    }

    if (!preferrableTenant) {
      return fallbackTenant;
    }

    const targetTeam = availableTeams.find((item) => item.attributes?.tenant?.data?.id === preferrableTenant);
    const targetRole = targetTeam?.attributes?.permissions;
    if (!targetTeam || !targetRole) {
      return fallbackTenant;
    }

    return {
      tenant: preferrableTenant,
      role: targetRole,
    };
  };

  useEffect(() => {
    if (!userLoading) {
      if (userData?.me) {
        const onboardingStatus = checkOnboardingStatus(userData.me);
        if (onboardingStatus) {
          const availableTeams = userData.me.tenant_teams?.data;
          const preferableTenant = userData.me.defaultTenantID;
          if (availableTeams) {
            setUserAuthData({
              ...userData.me,
              currentTenant: defineDefaultTenant(availableTeams, preferableTenant),
              availableTeams: getAvailableTeamsFromMeQuery(userData.me),
            });
          }
        } else {
          setUserAuthData({ ...userData.me, currentTenant: { tenant: null, role: "onboarder" }, availableTeams: getAvailableTeamsFromMeQuery(userData.me) });
        }
      } else {
        setUserAuthData(undefined);
      }
    }
  }, [userData, userLoading]);

  return <Context.Provider value={{ me: userAuthData, actions: { invalidate: invalidateFunction, refetch: refetchFunction } }}>{children}</Context.Provider>;
};

const useAuth = () => useContext<AuthContextProps>(Context);

export { AuthProvider, useAuth };
