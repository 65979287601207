export type GTMParams = { code: string };
export type GTMActionProps = { event: string; [key: string]: unknown };
declare const window: Window & { dataLayer: Record<string, unknown>[] };

const init = (params: GTMParams): void => {
  if (typeof window === "undefined") return;
  if (params.code.length === 0) return;

  const elementScript = document.createElement("script");
  const script = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${params.code}');`;
  elementScript.innerHTML = script;

  const elementNoscript = document.createElement("noscript");
  const noscript = `<iframe src="https://www.googletagmanager.com/ns.html?id=${params.code}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  elementNoscript.innerHTML = noscript;

  document.head.insertBefore(elementScript, document.head.childNodes[0]);
  document.body.insertBefore(elementNoscript, document.body.childNodes[0]);
};

const action = ({ event, ...rest }: GTMActionProps): void => {
  if (typeof window?.dataLayer?.push !== "undefined") {
    window.dataLayer.push({
      event,
      ...rest,
    });
  }
};

const GoogleTagManager = { init, action };

export default GoogleTagManager;
