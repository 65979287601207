import { FC, PropsWithChildren, memo } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Header } from "../header/Header";
import { useMeQuery } from "@/graphql";
import { useAuth } from "@/components/app/AuthProvider";
import GoogleTagManager from "@/utils/gtag";

const FullLayout: FC = () => {
  const { loading, error, data: { me: user } = {} } = useMeQuery();

  if (loading) {
    return null;
  }

  if (!loading && (error !== undefined || user === undefined)) {
    return <Navigate to={"/login"} replace />;
  }

  return (
    <>
      <div className={`container fullscreen`}>
        <Header skin={"dark"} isEditor />
      </div>
      <Outlet />
    </>
  );
};

const GlobalEnvelope: FC<PropsWithChildren> = ({ children }) => {
  GoogleTagManager.action({ event: "pageView" });
  return <>{children}</>;
};

export const BlankLayout: FC<PropsWithChildren> = ({ children }) => {
  return <div className={`container fullscreen`}>{children}</div>;
};

export const GenericPageLayout: FC<PropsWithChildren> = ({ children }) => {
  return <>{children}</>;
};

function defineRedirectPath(pathname: string) {
  let pathElements = pathname.split("/");
  pathElements.shift();
  pathElements = pathElements.filter((el) => !!el);
  if (pathElements.length >= 2) {
    const lastItem = pathElements[pathElements.length - 1];
    if (lastItem !== "view") {
      return `/${pathElements.join("/")}/view`;
    }
  }
  return "/login";
}

export const ProtectedRoute: FC = () => {
  const { me } = useAuth();
  const { pathname } = useLocation();
  if (me === null) return null; //TODO add loader
  if (me)
    return (
      <GlobalEnvelope>
        <Outlet />
      </GlobalEnvelope>
    );
  const redirectRoute = defineRedirectPath(pathname);
  return <Navigate to={redirectRoute} replace />;
};

export const SharedRoute: FC = () => {
  const { me } = useAuth();
  if (me === null) return null; //TODO add loader
  return (
    <GlobalEnvelope>
      <Outlet />
    </GlobalEnvelope>
  );
};

export const GuestRoute: FC = () => {
  return (
    <GlobalEnvelope>
      <Outlet />
    </GlobalEnvelope>
  );
};

export { FullLayout };
