import { FC, PropsWithChildren } from "react";
import "./common.scss";
import { Header } from "../header/Header";

const CommonOutcome: FC<PropsWithChildren<GenericResultOutcomeProps>> = (data) => {
  return (
    <>
      <div className={`container fullscreen`}>
        <Header skin={"light"} />
      </div>
      <div className={`container generic-result${data.isFullHeight ? " fullheight" : ""}`}>
        <div className="content-wrapper">
          <div className="picture">{data.slotImage}</div>
          <div className="message">{data.children}</div>
        </div>
      </div>
    </>
  );
};

const CommonOutcomeInline: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={`generic-result-inline`}>
      <div className="message">{children}</div>
    </div>
  );
};
export { CommonOutcome, CommonOutcomeInline };
