import { FC } from "react";
import "./pg.scss";
export const Pg: FC = () => {
  return (
    <div className={`pg`}>
      <div className={`pg-container`}>
        <div className={`top-el`}>Top</div>
        <div className={`mid-container`}>
          <div className={`mid-container-content`}>
            <div className={`item`}>Item</div>
            <div className={`item`}>Item</div>
            <div className={`item`}>Item</div>
            <div className={`item`}>Item</div>
            <div className={`item`}>Item</div>
            <div className={`item`}>Item</div>
            <div className={`item`}>Item</div>
            <div className={`item`}>Item</div>
            <div className={`item`}>Item</div>
            <div className={`item`}>Item</div>
            <div className={`item`}>Item</div>
            <div className={`item`}>Item</div>
            <div className={`item`}>Item</div>
            <div className={`item`}>Item</div>
          </div>
        </div>
        <div className={`bot-el`}>Bot</div>
      </div>
    </div>
  );
};
