import { FC, ReactNode, forwardRef } from "react";
import { Icon } from "../icon/Icon";
import "./toaster.scss";
import { Button } from "../button/Button";
import SuccessIcon from "./assets/success.svg?react";
import WarningIcon from "./assets/warning.svg?react";
import { CustomContentProps, closeSnackbar } from "notistack";

const iconMapping: Record<string, ReactNode> = {
  success: <SuccessIcon />,
  default: <SuccessIcon />,
  warning: <WarningIcon />,
};

const Toaster = forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
  const { message, variant, style, id } = props;
  return (
    <div className={`toaster`} style={style} ref={ref}>
      <div className={`content`}>
        <div className={`icon-container ${variant}`}>{iconMapping[variant]}</div>
        <div>{message}</div>
      </div>
      <Button variant={"ghost"} skin={"dark"} size={"small"} content={"icon"} onClick={() => closeSnackbar(id)}>
        <Icon name={"Close"} />
      </Button>
    </div>
  );
});

export { Toaster };
