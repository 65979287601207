import { FC, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { setAuthToken } from "@/utils/auth";
import { useAuth } from "@/components/app/AuthProvider";

const LoginRedirectPage: FC = () => {
  const {
    me,
    actions: { refetch },
  } = useAuth();
  const [isAuthed, setIsAuthed] = useState<Maybe<boolean>>(null);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const location = useLocation();
  async function loginConnect(query: string) {
    let response: any = null;
    try {
      response = await fetch(`${import.meta.env.WEBSITE_API_URL || ""}/api/auth/google/callback${query}`);
      response = await response.json();
      if (response) {
        setAuthToken(response.jwt);
        await refetch();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.warn(error);
      return false;
    }
  }

  useEffect(() => {
    if (location.search) {
      loginConnect(location.search).then((result) => {
        setIsAuthed(result);
      });
    }
  }, [location.search]);

  useEffect(() => {}, [isAuthed, me]);

  if (isAuthed === null) return null;
  if (isAuthed && me && !me.registeredAt) return <Navigate to="/assign-team" replace />;
  return isAuthed ? <Navigate to="/" replace /> : <Navigate to="/login" replace />;
};

export default LoginRedirectPage;
