import { FC } from "react";
import * as IconsLibrary from "./IconsLibrary";

type Icon = {
  name: IconNameOptions;
};

export const Icon: FC<Icon> = (props) => {
  const { name, ...svgProps } = props;

  const Icons: Record<Icon["name"], any> = {
    CheckboxEmpty: <IconsLibrary.CheckboxEmpty {...svgProps} />,
    CheckboxFilled: <IconsLibrary.CheckboxFilled {...svgProps} />,
    RadiobuttonEmpty: <IconsLibrary.RadiobuttonEmpty {...svgProps} />,
    RadiobuttonFilled: <IconsLibrary.RadiobuttonFilled {...svgProps} />,
    ThreeDots: <IconsLibrary.ThreeDots {...svgProps} />,
    Plus: <IconsLibrary.Plus {...svgProps} />,
    Back: <IconsLibrary.Back {...svgProps} />,
    Close: <IconsLibrary.Close {...svgProps} />,
    Delete: <IconsLibrary.Delete {...svgProps} />,
    Link: <IconsLibrary.Link {...svgProps} />,
    Download: <IconsLibrary.Download {...svgProps} />,
    Next: <IconsLibrary.Next {...svgProps} />,
    Search: <IconsLibrary.Search {...svgProps} />,
    Settings: <IconsLibrary.Settings {...svgProps} />,
    ExternalLink: <IconsLibrary.ExternalLink {...svgProps} />,
    StarEmpty: <IconsLibrary.StarEmpty {...svgProps} />,
    StarFilled: <IconsLibrary.StarFilled {...svgProps} />,
  };
  return Icons[name];
};
