import { FC } from "react";
import LogoSVG from "./assets/revisio-logo.svg?react";
import "./logo.scss";

export const Logo: FC<LogoProps> = ({ skin = "light" }) => {
  return (
    <div className={`logo ${skin}`}>
      <LogoSVG />
    </div>
  );
};
