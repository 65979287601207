import { Dropdown as MUIDropdown } from "@mui/base/Dropdown";
import { MenuButton as MUIMenuButton } from "@mui/base/MenuButton";
import { Menu as MUIMenu, MenuOwnProps as MUIMenuOwnProps } from "@mui/base/Menu";
import { MenuItem as MUIMenuItem, MenuItemOwnProps as MUIMenuItemOwnProps } from "@mui/base/MenuItem";
import { FC, PropsWithChildren } from "react";
import "./menu.scss";

type MenuItemProps = {
  label: string;
  action: () => void;
};
type MenuProps = {
  options: Array<MenuItemProps>;
  openAction?: () => void;
  closeAction?: () => void;
};

const Menu: FC<PropsWithChildren<MenuProps>> = (props) => {
  const { openAction = () => {}, closeAction = () => {} } = props;

  const closeMenu = () => {
    closeAction();
  };
  const openMenu = () => {
    openAction();
  };

  const menuSlotProps: MUIMenuOwnProps["slotProps"] = {
    root: {
      className: "menu-dropdown-container",
    },
    listbox: {
      className: "listbox",
    },
  };
  const menuItemSlotProps: MUIMenuItemOwnProps["slotProps"] = {
    root: {
      className: `menu-item`,
    },
  };

  return (
    <MUIDropdown onOpenChange={(_event, open) => (open ? openMenu() : closeMenu())}>
      <MUIMenuButton>{props.children}</MUIMenuButton>
      <MUIMenu slotProps={menuSlotProps}>
        {props.options.map((item, index) => (
          <MUIMenuItem
            slotProps={menuItemSlotProps}
            onClick={() => {
              item.action();
            }}
            key={index}
          >
            {item.label}
          </MUIMenuItem>
        ))}
      </MUIMenu>
    </MUIDropdown>
  );
};

export { Menu };
