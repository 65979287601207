import { FC } from "react";
import "./headerTab.scss";
import { NavLink } from "react-router-dom";

type HeaderTabItem = {
  title: string;
  link: string;
  reloadDocument?: boolean;
};
const HeaderTabItem: FC<HeaderTabItem> = ({ title, link, reloadDocument = false }) => {
  return (
    <NavLink
      to={`/${link}`}
      reloadDocument={reloadDocument}
      className={({ isActive, isPending }) => `header-tab-item${isPending ? " pending" : isActive ? " active" : ""}`}
    >
      {title}
    </NavLink>
  );
};

const headerConfig: Array<HeaderTabItem> = [{ title: "Projects", link: "projects" }];
export const HeaderTab: FC = () => {
  return (
    <div className={`header-tab`}>
      {headerConfig.map((item, index) => (
        <HeaderTabItem {...item} key={index} />
      ))}
    </div>
  );
};
