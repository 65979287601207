import { createContext, Dispatch, FC, PropsWithChildren, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { useAddScreenMutation, useUpdateProjectCoverMutation, useUploadFileMutation } from "@/graphql";

export type UploadScreensDataProps = {
  queue: Array<ScreenBlueprint>;
  processedProjects: Array<string>;
  isDirty: boolean;
  isUploadAvailable: boolean;
};
export type UploadScreensActionsProps = {
  addItemsToQueue: Dispatch<SetStateAction<ScreenBlueprint[]>>;
  setDirty: Dispatch<SetStateAction<boolean>>;
  setUploadAvailability: Dispatch<SetStateAction<boolean>>;
};

export type UploadScreensContextProps = UploadScreensDataProps & UploadScreensActionsProps;

const defaultDataValue: UploadScreensDataProps = {
  queue: [],
  processedProjects: [],
  isDirty: false,
  isUploadAvailable: false,
};

const defaultActionsValue: UploadScreensActionsProps = {
  addItemsToQueue: () => {},
  setDirty: () => {},
  setUploadAvailability: () => {},
};

const Context = createContext<UploadScreensContextProps>({ ...defaultDataValue, ...defaultActionsValue });

function processSizes(width: number | null | undefined, height: number | null | undefined, forcedDensity?: number | null) {
  if (!(width && height)) return null;
  if (forcedDensity) {
    return {
      width: Math.floor(width / forcedDensity),
      height: Math.floor(height / forcedDensity),
      density: forcedDensity,
    };
  }
  const result = {
    width: 0,
    height: 0,
    density: 0,
  };
  const isLandscape = width / height >= 0;
  const baseWidth = 1280;
  const horizontalThreshold = baseWidth * 2;

  if (width > horizontalThreshold && isLandscape) {
    result.density = Math.floor(width / baseWidth);
    result.width = Math.floor(width / result.density);
    result.height = Math.floor(height / result.density);
  } else {
    result.width = width;
    result.height = height;
    result.density = 1;
  }
  return result;
}

const densityMatcher = `(?<=@)(.*?)(?=[xX])`;
const densityRegExp = new RegExp(densityMatcher);
const fileExtensionMatcher = `\\.[^.]+$`;
const fileExtensionRegExp = new RegExp(fileExtensionMatcher);

function normalizeFileName(name: string | null | undefined) {
  if (!name) return "Undefined";
  let result = name.replace(fileExtensionRegExp, "");
  if (densityRegExp.test(result)) {
    result = result.substring(0, result.lastIndexOf(`@`));
  }
  return result;
}

function defineForcedDensity(name: string | null | undefined): number | null {
  if (!name) return null;

  const densityIdentifiers = densityRegExp.exec(name);
  if (!densityIdentifiers || densityIdentifiers.length == 0) return null;
  const value = parseFloat(densityIdentifiers[densityIdentifiers.length - 1]);
  if (isNaN(value)) return null;
  const precision = 10;
  return Math.round(value * precision) / precision;
}

const UploadScreensProvider: FC<PropsWithChildren<Partial<ReactNode>>> = ({ children }) => {
  const [currentUploadQueue, setCurrentUploadQueue] = useState<UploadScreensContextProps["queue"]>(defaultDataValue.queue);
  const [affectedProjectsQueue, setAffectedProjectsQueue] = useState<Array<string>>([]);
  const [incomingItems, setIncomingItems] = useState<UploadScreensContextProps["queue"]>([]);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isUploadAvailable, setIsUploadAvailable] = useState<boolean>(false);
  const [uploadRequest, { loading: uploadRequestLoading, called: uploadRequestCalled, error: uploadRequestError }] = useUploadFileMutation();
  const [newScreenRequest, { loading: newScreenRequestLoading, called: newScreenRequestCalled, error: newScreenRequestError }] = useAddScreenMutation();
  const [updateProjectCoverRequest] = useUpdateProjectCoverMutation();

  // Main upload function

  useEffect(() => {
    if (currentUploadQueue.length > 0) {
      try {
        if (currentUploadQueue[0].project && currentUploadQueue[0].file) {
          setAffectedProjectsQueue((existing) => [...existing, currentUploadQueue[0].project]);
          uploadRequest({ variables: { file: currentUploadQueue[0].file } }).then((result) => {
            const asset: Maybe<FileFragment> = result.data?.upload.data ?? null;
            if (asset) {
              const normalizedDensity = defineForcedDensity(asset.attributes?.name);
              const normalizedName = normalizeFileName(asset.attributes?.name);
              const normalizedSize = processSizes(asset.attributes?.width, asset.attributes?.height, normalizedDensity);

              newScreenRequest({
                variables: {
                  input: {
                    project: currentUploadQueue[0].project,
                    name: normalizedName,
                    media: asset.id,
                    ...normalizedSize,
                  },
                },
              }).then((result) => {
                setIsDirty(() => true);
                setCurrentUploadQueue((prev) => prev.slice(1));
              });
            }
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
      }
    }
  }, [currentUploadQueue]);

  // Happens when user starts uploading items

  useEffect(() => {
    if (incomingItems.length > 0) {
      setCurrentUploadQueue((prevState) => [...prevState.concat(incomingItems)]);
      setIncomingItems([]);
    }
  }, [incomingItems]);

  // Happens when screen upload is complete and it is time to update project's cover

  useEffect(() => {
    if (currentUploadQueue.length === 0 && affectedProjectsQueue.length > 0) {
      Promise.all(affectedProjectsQueue.map((el) => updateProjectCoverRequest({ variables: { id: el } }))).then(() => {
        setAffectedProjectsQueue(() => []);
      });
    }
  }, [affectedProjectsQueue, currentUploadQueue.length]);

  return (
    <Context.Provider
      value={{
        queue: currentUploadQueue,
        isDirty: isDirty,
        processedProjects: affectedProjectsQueue,
        isUploadAvailable: isUploadAvailable,
        addItemsToQueue: setIncomingItems,
        setDirty: setIsDirty,
        setUploadAvailability: setIsUploadAvailable,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useUploadScreensProvider = () => useContext<UploadScreensContextProps>(Context);

export { UploadScreensProvider, useUploadScreensProvider };
