import { FC } from "react";

import { CommonOutcome, CommonOutcomeInline } from "./Common";
import Image from "./assets/illustration-notfound.svg?react";

const Notfound: FC<ErrorOutcomeProps> = (data) => {
  const errorTitle = data.title ?? "Not found";
  const errorDescription = data.description ?? `Nothing ahead. Maybe the link is broken, or it is expired.`;

  return (
    <CommonOutcome slotImage={<Image />} isFullHeight>
      <span className={`like-h1`}>{errorTitle}</span>
      <p className="description">{errorDescription}</p>
    </CommonOutcome>
  );
};

const NotfoundInline: FC<BasicResultOutcomeProps> = (data) => {
  const errorTitle = data.title ?? "Not found";
  const errorDescription = data.description ?? `Something happened. Maybe the link is invalid, or something went wrong.`;

  return (
    <CommonOutcomeInline>
      <p className="description">{errorTitle}</p>
      <p className="description">{errorDescription}</p>
    </CommonOutcomeInline>
  );
};
export { Notfound, NotfoundInline };
