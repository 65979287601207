import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from "@apollo/client";
import { FC, memo, Suspense, useMemo } from "react";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";
import { setContext } from "@apollo/client/link/context";
import "@fontsource/spectral";
import "../../styles/global.scss";
import { getAuthToken } from "@/utils/auth";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import introspection from "@/graphql";
import router from "../../pages";
import { BaseSettingsProvider } from "./BaseSettingsProvider";
import { CurrentUserSettingsProvider } from "./CurrentUserSettingsProvider";
import { AuthProvider } from "./AuthProvider";
import { TenantProvider } from "./TenantProvider";
import { ProjectProvider } from "./ProjectProvider";
import { UploadScreensProvider } from "@/components/app/UploadScreensProvider";
import { NotificationsProvider } from "../toaster/notistackCustomization";
import GoogleTagManager from "@/utils/gtag";

const devEnvironment: boolean = import.meta.env.DEV ?? true;

const uploadLink = createUploadLink({
  uri: import.meta.env.WEBSITE_API_URL ? `${import.meta.env.WEBSITE_API_URL}/graphql` : "/graphql",
  credentials: "same-origin",
});

const authLink = setContext((_, { headers }) => {
  const token = getAuthToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : undefined,
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, uploadLink]),
  connectToDevTools: devEnvironment,
  queryDeduplication: true,
  cache: new InMemoryCache({
    resultCaching: true,
    possibleTypes: introspection.possibleTypes,
    typePolicies: {
      UploadFile: {
        keyFields: ["url"],
      },
      TagCategory: {
        keyFields: ["displayName"],
      },
      Tenant: {
        keyFields: ["slug"],
      },
      ProjectEntity: {
        fields: {
          attributes: {
            merge: true,
          },
        },
      },
      TagEntity: {
        fields: {
          attributes: {
            merge: true,
          },
        },
      },
    }, //TODO to remove issues
  }),
});

GoogleTagManager.init({ code: import.meta.env.WEBSITE_GTM_IDENTIFIER ?? "" });

const App: FC = () => {
  return (
    <ApolloProvider client={client}>
      <NotificationsProvider>
        <AuthProvider>
          <TenantProvider>
            <BaseSettingsProvider>
              <ProjectProvider>
                <UploadScreensProvider>
                  <HelmetProvider>
                    <CurrentUserSettingsProvider>
                      <Suspense>
                        <RouterProvider router={router} />
                      </Suspense>
                    </CurrentUserSettingsProvider>
                  </HelmetProvider>
                </UploadScreensProvider>
              </ProjectProvider>
            </BaseSettingsProvider>
          </TenantProvider>
        </AuthProvider>
      </NotificationsProvider>
    </ApolloProvider>
  );
};

export default memo(App);

export { App };
