import { createContext, FC, PropsWithChildren, ReactNode, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useAuth } from "@/components/app/AuthProvider";
import { useProjectQuery, useProjectTagsQuery } from "@/graphql";

//null - we don't know project yet
//undefined - no project exists
export type ProjectDataProps = { project: ProjectFullFragment | null | undefined };
export type ProjectActionsProps = { setProjectID: Dispatch<SetStateAction<number | null>> };
export type ProjectTagCategoriesCombinedDataProps = { projectTags: Array<TagCategoryProps> | null };
export type ProjectTagCategoriesCombinedActionsProps = { setProjectTCCs: (tagData: Array<TagFullFragment>) => void };

export type ProjectContextProps = ProjectDataProps & ProjectActionsProps;
export type ProjectTagCategoriesCombinedContextProps = ProjectTagCategoriesCombinedDataProps & ProjectTagCategoriesCombinedActionsProps;

const defaultDataValue: ProjectContextProps = {
  project: undefined,
  setProjectID: () => {},
};

const defaultTagsValue: ProjectTagCategoriesCombinedContextProps = {
  projectTags: null,
  setProjectTCCs: () => {},
};

type CategoryInTag = { id?: string | null; attributes?: { type: EnumTagcategoryType; displayName?: string | null } | null } | null | undefined;

function groupTagsIntoCategories(tagData: Array<TagFullFragment>): Array<TagCategoryProps> {
  const rawData = tagData;
  const rawCategoriesArr = rawData.map((value) => value.attributes?.tag_category?.data);
  const uniqueCategoriesArr = Array.from(new Set(rawCategoriesArr.map((item) => item?.id)));
  const rawCategories: Array<CategoryInTag> = [];
  uniqueCategoriesArr.forEach((item) => {
    rawCategories.push(rawCategoriesArr.find((el) => el?.id === item));
  });
  const categories: Array<TagCategoryProps> = [];
  rawCategories.forEach((value) => {
    const categoryTags: Array<TagBodyProps> = rawData
      .filter((item) => item.attributes?.tag_category?.data?.id === value?.id)
      .map((item) => {
        return {
          id: item.id ?? "",
          displayName: item.attributes?.displayName ?? "",
        };
      });
    categories.push({
      id: value?.id ?? "0",
      displayName: value?.attributes?.displayName ?? "",
      type: value?.attributes?.type ?? "multi_select",
      items: categoryTags,
    });
  });
  return categories;
}

const Context = createContext<ProjectContextProps & ProjectTagCategoriesCombinedContextProps>({ ...defaultDataValue, ...defaultTagsValue });

const ProjectProvider: FC<PropsWithChildren<Partial<ReactNode>>> = ({ children }) => {
  const { me } = useAuth();
  const [projectID, setProjectID] = useState<number | null>(null);
  const projectDataQueryResult = useProjectQuery({ skip: !projectID, variables: { id: projectID?.toString(10) ?? "" } });
  const tagsDataQueryResult = useProjectTagsQuery({ skip: !projectID || !me, variables: { project: projectID?.toString(10) ?? "" } });
  const [projectData, setProjectData] = useState<ProjectDataProps>(defaultDataValue);
  const [projectTagsData, setProjectTagsData] = useState<ProjectTagCategoriesCombinedDataProps["projectTags"]>(defaultTagsValue.projectTags);

  useEffect(() => {
    if (!projectDataQueryResult.loading && projectDataQueryResult.data?.project?.data) {
      setProjectData(() => {
        return { project: { ...projectDataQueryResult.data?.project?.data } };
      });
    } else {
      setProjectData(() => {
        return { project: undefined };
      });
    }
  }, [projectDataQueryResult]);

  useEffect(() => {
    if (!tagsDataQueryResult.loading && tagsDataQueryResult.data?.tags?.data) {
      setProjectTagsData(groupTagsIntoCategories(tagsDataQueryResult.data.tags.data));
    } else {
      setProjectTagsData([]);
    }
  }, [tagsDataQueryResult]);

  const setProjectTCCs = (tagData: Array<TagFullFragment>) => {
    setProjectTagsData(groupTagsIntoCategories(tagData));
  };

  return <Context.Provider value={{ ...projectData, projectTags: projectTagsData, setProjectID, setProjectTCCs: setProjectTCCs }}>{children}</Context.Provider>;
};

const useProjectProvider = () => useContext<ProjectContextProps & ProjectTagCategoriesCombinedContextProps>(Context);

export { ProjectProvider, useProjectProvider };
