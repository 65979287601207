import { Button as MUIButton, ButtonProps as MUIButtonProps } from "@mui/base/Button";
import { FC, PropsWithChildren } from "react";
import "./button.scss";
import { Link } from "@/components/icon/IconsLibrary";

type ButtonExt = MUIButtonProps & {
  size?: "default" | "big" | "large" | "small";
  skin?: SkinOptions;
  variant?: "default" | "primary" | "ghost" | "danger";
  content?: "default" | "text" | "icon";
  isFake?: boolean;
};
export const Button: FC<PropsWithChildren<ButtonExt>> = (props) => {
  const { isFake = false, ...rest } = props;
  const addedClassName =
    "button-root " +
    (props.size ? `size-${props.size} ` : "size-default ") +
    (props.skin ? `skin-${props.skin} ` : "skin-light ") +
    (props.content ? `content-${props.content} ` : "content-text ") +
    (props.variant ? `variant-${props.variant}` : "variant-default");

  const slotProps: MUIButtonProps["slotProps"] = {
    root: {
      className: addedClassName,
    },
  };

  if (isFake) return <div className={addedClassName} children={props.children} />;
  if (props.to) return <Link className={addedClassName} children={props.children} to={props.to} />;
  if (props.href) return <a className={addedClassName} children={props.children} href={props.href} />;
  return <MUIButton {...rest} slotProps={slotProps} />;
};
