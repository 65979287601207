import { FC } from "react";
import "./avatar.scss";

type Avatar = {
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
};
export const Avatar: FC<Avatar> = ({ firstName, lastName }) => {
  const firstLetter = firstName ? firstName[0] : "";
  const secondLetter = lastName ? lastName[0] : "";
  const initials = (firstLetter + secondLetter).toUpperCase();
  return <div className={"avatar"}>{initials}</div>;
};
