import { FC } from "react";

import { CommonOutcome } from "./Common";
import Image from "./assets/illustration-error.svg?react";

const Error: FC<ErrorOutcomeProps> = (data) => {
  const errorTitle = data.title ?? "Oh, snap!";
  const errorDescription = data.description ?? `Something went wrong on our side.\r\n Please try again later`;

  return (
    <CommonOutcome slotImage={<Image />} isFullHeight>
      <span className={`like-h1`}>{errorTitle}</span>
      <p className="description">{errorDescription}</p>
    </CommonOutcome>
  );
};
export { Error };
