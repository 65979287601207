import { SnackbarProvider } from "notistack";
import { FC, PropsWithChildren } from "react";
import { Toaster } from "./Toaster";
import Grow from "./Grow";
const NotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <SnackbarProvider
      transitionDuration={{ enter: 250, exit: 150 }}
      maxSnack={1}
      TransitionComponent={Grow}
      disableWindowBlurListener={true}
      autoHideDuration={3000}
      preventDuplicate
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      classes={{ containerRoot: "toaster-root" }}
      Components={{
        success: Toaster,
        warning: Toaster,
        default: Toaster,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export { NotificationsProvider };
