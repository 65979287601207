import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentSpecificTenantAssignment",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "Project",
      "Screen",
      "Tag",
      "TagCategory",
      "Tenant",
      "TenantTeam",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentSpecificTenantAssignment",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "Project",
      "Screen",
      "Tag",
      "TagCategory",
      "Tenant",
      "TenantTeam",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      export default result;
    
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    height
    width
    updatedAt
    url
    previewUrl
    name
  }
}
    `;
export const TenantTeamFragmentDoc = gql`
    fragment TenantTeam on TenantTeamEntity {
  id
  attributes {
    permissions
    tenant {
      data {
        id
        attributes {
          domain
          companyName
          slug
        }
      }
    }
  }
}
    `;
export const MeFragmentDoc = gql`
    fragment Me on UsersPermissionsUser {
  email
  id
  firstName
  lastName
  defaultTenantID
  registeredAt
  tenant_teams {
    data {
      ...TenantTeam
    }
  }
}
    ${TenantTeamFragmentDoc}`;
export const TagExtendedFragmentDoc = gql`
    fragment TagExtended on TagEntity {
  id
  attributes {
    displayName
    isSystem
    tag_category {
      data {
        id
        attributes {
          type
          displayName
        }
      }
    }
  }
}
    `;
export const ProjectBaseFragmentDoc = gql`
    fragment ProjectBase on ProjectEntity {
  id
  attributes {
    name
    source
    updatedAt
    imagePreview
    projectType
    tags(pagination: {pageSize: 1000}) {
      data {
        ...TagExtended
      }
    }
  }
}
    ${TagExtendedFragmentDoc}`;
export const ScreenFragmentDoc = gql`
    fragment Screen on ScreenEntity {
  id
  attributes {
    name
    updatedAt
    width
    height
    density
    media {
      data {
        id
        attributes {
          previewUrl
          url
          height
          width
          formats
        }
      }
    }
  }
}
    `;
export const TagFullFragmentDoc = gql`
    fragment TagFull on TagEntity {
  id
  attributes {
    displayName
    isSystem
    tag_category {
      data {
        id
        attributes {
          type
          displayName
        }
      }
    }
    projects(filters: {deletedAt: null}) {
      data {
        id
      }
    }
  }
}
    `;
export const ProjectFullFragmentDoc = gql`
    fragment ProjectFull on ProjectEntity {
  id
  attributes {
    name
    source
    updatedAt
    imagePreview
    projectType
    order
    screens(filters: {deletedAt: null}, pagination: {pageSize: 1000}) {
      data {
        ...Screen
      }
    }
    tags(pagination: {pageSize: 1000}) {
      data {
        ...TagFull
      }
    }
    screensUpdatedAt
    backgroundColor
    uiColor
    frameColor
    frameType
  }
}
    ${ScreenFragmentDoc}
${TagFullFragmentDoc}`;
export const ProjectGuestFragmentDoc = gql`
    fragment ProjectGuest on ProjectEntity {
  id
  attributes {
    name
    source
    updatedAt
    imagePreview
    projectType
    order
    screens(filters: {deletedAt: null}, pagination: {pageSize: 1000}) {
      data {
        ...Screen
      }
    }
    backgroundColor
    uiColor
    frameColor
    frameType
  }
}
    ${ScreenFragmentDoc}`;
export const TagFragmentDoc = gql`
    fragment Tag on TagEntity {
  id
  attributes {
    displayName
    isSystem
    tag_category {
      data {
        id
      }
    }
  }
}
    `;
export const TagCategoryFragmentDoc = gql`
    fragment TagCategory on TagCategoryEntity {
  id
  attributes {
    displayName
    type
    isSystem
    tags {
      data {
        id
        attributes {
          displayName
        }
      }
    }
  }
}
    `;
export const TenantFragmentDoc = gql`
    fragment Tenant on TenantEntity {
  id
  attributes {
    slug
    companyName
    domain
    projects(filters: {deletedAt: null}, pagination: {pageSize: 10000}) {
      data {
        id
      }
    }
    tags(pagination: {pageSize: 10000}) {
      data {
        ...TagFull
      }
    }
    tag_categories(pagination: {pageSize: 10000}) {
      data {
        ...TagCategory
      }
    }
  }
}
    ${TagFullFragmentDoc}
${TagCategoryFragmentDoc}`;
 const UpdateMeDocument = gql`
    mutation updateMe($id: ID!, $input: UsersPermissionsUserInput!) {
  updateUsersPermissionsUser(id: $id, data: $input) {
    data {
      id
      attributes {
        ...Me
      }
    }
  }
}
    ${MeFragmentDoc}`;
export type UpdateMeMutationFn = Apollo.MutationFunction<UpdateMeMutation, UpdateMeMutationVariables>;
export function useUpdateMeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options);
      }
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
 const UpdateMyTeamAssignmentDocument = gql`
    mutation updateMyTeamAssignment($tenantID: ID!, $role: ENUM_TENANTTEAM_PERMISSIONS!) {
  updateMyTeamAssignment(tenantID: $tenantID, role: $role) {
    ...Me
  }
}
    ${MeFragmentDoc}`;
export type UpdateMyTeamAssignmentMutationFn = Apollo.MutationFunction<UpdateMyTeamAssignmentMutation, UpdateMyTeamAssignmentMutationVariables>;
export function useUpdateMyTeamAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMyTeamAssignmentMutation, UpdateMyTeamAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMyTeamAssignmentMutation, UpdateMyTeamAssignmentMutationVariables>(UpdateMyTeamAssignmentDocument, options);
      }
export type UpdateMyTeamAssignmentMutationHookResult = ReturnType<typeof useUpdateMyTeamAssignmentMutation>;
export type UpdateMyTeamAssignmentMutationResult = Apollo.MutationResult<UpdateMyTeamAssignmentMutation>;
 const NewProjectDocument = gql`
    mutation newProject($input: ProjectInput!) {
  createProject(data: $input) {
    data {
      ...ProjectFull
    }
  }
}
    ${ProjectFullFragmentDoc}`;
export type NewProjectMutationFn = Apollo.MutationFunction<NewProjectMutation, NewProjectMutationVariables>;
export function useNewProjectMutation(baseOptions?: Apollo.MutationHookOptions<NewProjectMutation, NewProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewProjectMutation, NewProjectMutationVariables>(NewProjectDocument, options);
      }
export type NewProjectMutationHookResult = ReturnType<typeof useNewProjectMutation>;
export type NewProjectMutationResult = Apollo.MutationResult<NewProjectMutation>;
 const UpdateProjectDocument = gql`
    mutation updateProject($id: ID!, $input: ProjectInput!) {
  updateProject(id: $id, data: $input) {
    data {
      ...ProjectFull
    }
  }
}
    ${ProjectFullFragmentDoc}`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
 const DeleteProjectDocument = gql`
    mutation deleteProject($id: ID!) {
  deleteProject(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteProjectMutationFn = Apollo.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
 const SoftDeleteProjectDocument = gql`
    mutation softDeleteProject($id: ID!) {
  softDeleteProject(id: $id) {
    id
  }
}
    `;
export type SoftDeleteProjectMutationFn = Apollo.MutationFunction<SoftDeleteProjectMutation, SoftDeleteProjectMutationVariables>;
export function useSoftDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<SoftDeleteProjectMutation, SoftDeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftDeleteProjectMutation, SoftDeleteProjectMutationVariables>(SoftDeleteProjectDocument, options);
      }
export type SoftDeleteProjectMutationHookResult = ReturnType<typeof useSoftDeleteProjectMutation>;
export type SoftDeleteProjectMutationResult = Apollo.MutationResult<SoftDeleteProjectMutation>;
 const UpdateProjectCoverDocument = gql`
    mutation updateProjectCover($id: ID!) {
  updateProjectCover(id: $id)
}
    `;
export type UpdateProjectCoverMutationFn = Apollo.MutationFunction<UpdateProjectCoverMutation, UpdateProjectCoverMutationVariables>;
export function useUpdateProjectCoverMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectCoverMutation, UpdateProjectCoverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectCoverMutation, UpdateProjectCoverMutationVariables>(UpdateProjectCoverDocument, options);
      }
export type UpdateProjectCoverMutationHookResult = ReturnType<typeof useUpdateProjectCoverMutation>;
export type UpdateProjectCoverMutationResult = Apollo.MutationResult<UpdateProjectCoverMutation>;
 const AddScreenDocument = gql`
    mutation addScreen($input: ScreenInput!) {
  addScreen(data: $input) {
    data {
      ...Screen
    }
  }
}
    ${ScreenFragmentDoc}`;
export type AddScreenMutationFn = Apollo.MutationFunction<AddScreenMutation, AddScreenMutationVariables>;
export function useAddScreenMutation(baseOptions?: Apollo.MutationHookOptions<AddScreenMutation, AddScreenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddScreenMutation, AddScreenMutationVariables>(AddScreenDocument, options);
      }
export type AddScreenMutationHookResult = ReturnType<typeof useAddScreenMutation>;
export type AddScreenMutationResult = Apollo.MutationResult<AddScreenMutation>;
 const DeleteScreenDocument = gql`
    mutation deleteScreen($id: ID!) {
  deleteScreen(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteScreenMutationFn = Apollo.MutationFunction<DeleteScreenMutation, DeleteScreenMutationVariables>;
export function useDeleteScreenMutation(baseOptions?: Apollo.MutationHookOptions<DeleteScreenMutation, DeleteScreenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteScreenMutation, DeleteScreenMutationVariables>(DeleteScreenDocument, options);
      }
export type DeleteScreenMutationHookResult = ReturnType<typeof useDeleteScreenMutation>;
export type DeleteScreenMutationResult = Apollo.MutationResult<DeleteScreenMutation>;
 const SoftDeleteScreenDocument = gql`
    mutation softDeleteScreen($id: ID!) {
  softDeleteScreen(id: $id) {
    id
  }
}
    `;
export type SoftDeleteScreenMutationFn = Apollo.MutationFunction<SoftDeleteScreenMutation, SoftDeleteScreenMutationVariables>;
export function useSoftDeleteScreenMutation(baseOptions?: Apollo.MutationHookOptions<SoftDeleteScreenMutation, SoftDeleteScreenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftDeleteScreenMutation, SoftDeleteScreenMutationVariables>(SoftDeleteScreenDocument, options);
      }
export type SoftDeleteScreenMutationHookResult = ReturnType<typeof useSoftDeleteScreenMutation>;
export type SoftDeleteScreenMutationResult = Apollo.MutationResult<SoftDeleteScreenMutation>;
 const NewTagCategoryDocument = gql`
    mutation newTagCategory($input: TagCategoryInput!) {
  createTagCategory(data: $input) {
    data {
      ...TagCategory
    }
  }
}
    ${TagCategoryFragmentDoc}`;
export type NewTagCategoryMutationFn = Apollo.MutationFunction<NewTagCategoryMutation, NewTagCategoryMutationVariables>;
export function useNewTagCategoryMutation(baseOptions?: Apollo.MutationHookOptions<NewTagCategoryMutation, NewTagCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewTagCategoryMutation, NewTagCategoryMutationVariables>(NewTagCategoryDocument, options);
      }
export type NewTagCategoryMutationHookResult = ReturnType<typeof useNewTagCategoryMutation>;
export type NewTagCategoryMutationResult = Apollo.MutationResult<NewTagCategoryMutation>;
 const UpdateTagCategoryDocument = gql`
    mutation updateTagCategory($id: ID!, $input: TagCategoryInput!) {
  updateTagCategory(id: $id, data: $input) {
    data {
      ...TagCategory
    }
  }
}
    ${TagCategoryFragmentDoc}`;
export type UpdateTagCategoryMutationFn = Apollo.MutationFunction<UpdateTagCategoryMutation, UpdateTagCategoryMutationVariables>;
export function useUpdateTagCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagCategoryMutation, UpdateTagCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTagCategoryMutation, UpdateTagCategoryMutationVariables>(UpdateTagCategoryDocument, options);
      }
export type UpdateTagCategoryMutationHookResult = ReturnType<typeof useUpdateTagCategoryMutation>;
export type UpdateTagCategoryMutationResult = Apollo.MutationResult<UpdateTagCategoryMutation>;
 const DeleteTagCategoryDocument = gql`
    mutation deleteTagCategory($id: ID!) {
  deleteTagCategory(id: $id) {
    data {
      ...TagCategory
    }
  }
}
    ${TagCategoryFragmentDoc}`;
export type DeleteTagCategoryMutationFn = Apollo.MutationFunction<DeleteTagCategoryMutation, DeleteTagCategoryMutationVariables>;
export function useDeleteTagCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagCategoryMutation, DeleteTagCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTagCategoryMutation, DeleteTagCategoryMutationVariables>(DeleteTagCategoryDocument, options);
      }
export type DeleteTagCategoryMutationHookResult = ReturnType<typeof useDeleteTagCategoryMutation>;
export type DeleteTagCategoryMutationResult = Apollo.MutationResult<DeleteTagCategoryMutation>;
 const NewTagDocument = gql`
    mutation newTag($input: TagInput!) {
  createTag(data: $input) {
    data {
      ...TagFull
    }
  }
}
    ${TagFullFragmentDoc}`;
export type NewTagMutationFn = Apollo.MutationFunction<NewTagMutation, NewTagMutationVariables>;
export function useNewTagMutation(baseOptions?: Apollo.MutationHookOptions<NewTagMutation, NewTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewTagMutation, NewTagMutationVariables>(NewTagDocument, options);
      }
export type NewTagMutationHookResult = ReturnType<typeof useNewTagMutation>;
export type NewTagMutationResult = Apollo.MutationResult<NewTagMutation>;
 const UpdateTagDocument = gql`
    mutation updateTag($id: ID!, $input: TagInput!) {
  updateTag(id: $id, data: $input) {
    data {
      ...TagFull
    }
  }
}
    ${TagFullFragmentDoc}`;
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;
export function useUpdateTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, options);
      }
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
 const DeleteTagDocument = gql`
    mutation deleteTag($id: ID!) {
  deleteTag(id: $id) {
    data {
      ...TagFull
    }
  }
}
    ${TagFullFragmentDoc}`;
export type DeleteTagMutationFn = Apollo.MutationFunction<DeleteTagMutation, DeleteTagMutationVariables>;
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DeleteTagDocument, options);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
 const UpdateTenantTagsDocument = gql`
    mutation updateTenantTags($id: ID!, $input: [ID]!) {
  updateTenant(id: $id, data: {tags: $input}) {
    data {
      ...Tenant
    }
  }
}
    ${TenantFragmentDoc}`;
export type UpdateTenantTagsMutationFn = Apollo.MutationFunction<UpdateTenantTagsMutation, UpdateTenantTagsMutationVariables>;
export function useUpdateTenantTagsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantTagsMutation, UpdateTenantTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantTagsMutation, UpdateTenantTagsMutationVariables>(UpdateTenantTagsDocument, options);
      }
export type UpdateTenantTagsMutationHookResult = ReturnType<typeof useUpdateTenantTagsMutation>;
export type UpdateTenantTagsMutationResult = Apollo.MutationResult<UpdateTenantTagsMutation>;
 const UpdateTenantCategoriesDocument = gql`
    mutation updateTenantCategories($id: ID!, $input: [ID]!) {
  updateTenant(id: $id, data: {tag_categories: $input}) {
    data {
      ...Tenant
    }
  }
}
    ${TenantFragmentDoc}`;
export type UpdateTenantCategoriesMutationFn = Apollo.MutationFunction<UpdateTenantCategoriesMutation, UpdateTenantCategoriesMutationVariables>;
export function useUpdateTenantCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantCategoriesMutation, UpdateTenantCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantCategoriesMutation, UpdateTenantCategoriesMutationVariables>(UpdateTenantCategoriesDocument, options);
      }
export type UpdateTenantCategoriesMutationHookResult = ReturnType<typeof useUpdateTenantCategoriesMutation>;
export type UpdateTenantCategoriesMutationResult = Apollo.MutationResult<UpdateTenantCategoriesMutation>;
 const UploadFileDocument = gql`
    mutation uploadFile($refId: ID, $ref: String, $field: String, $info: FileInfoInput, $file: Upload!) {
  upload(refId: $refId, ref: $ref, field: $field, info: $info, file: $file) {
    data {
      ...File
    }
  }
}
    ${FileFragmentDoc}`;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
 const MeDocument = gql`
    query me {
  me {
    ...Me
  }
}
    ${MeFragmentDoc}`;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
 const ProjectDocument = gql`
    query project($id: ID!) {
  project(id: $id) {
    data {
      ...ProjectFull
    }
  }
}
    ${ProjectFullFragmentDoc}`;
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
 const ProjectTagsDocument = gql`
    query projectTags($project: ID!) {
  tags(filters: {projects: {id: {eq: $project}}}, pagination: {pageSize: 1000}) {
    data {
      ...TagFull
    }
  }
}
    ${TagFullFragmentDoc}`;
export function useProjectTagsQuery(baseOptions: Apollo.QueryHookOptions<ProjectTagsQuery, ProjectTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectTagsQuery, ProjectTagsQueryVariables>(ProjectTagsDocument, options);
      }
export function useProjectTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectTagsQuery, ProjectTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectTagsQuery, ProjectTagsQueryVariables>(ProjectTagsDocument, options);
        }
export type ProjectTagsQueryHookResult = ReturnType<typeof useProjectTagsQuery>;
export type ProjectTagsLazyQueryHookResult = ReturnType<typeof useProjectTagsLazyQuery>;
export type ProjectTagsQueryResult = Apollo.QueryResult<ProjectTagsQuery, ProjectTagsQueryVariables>;
 const MyProjectsDocument = gql`
    query myProjects($tenant: ID!) {
  projects(
    filters: {tenant: {id: {eq: $tenant}}, deletedAt: null}
    pagination: {pageSize: 1000}
  ) {
    data {
      ...ProjectBase
    }
    meta {
      pagination {
        page
        pageSize
        pageCount
        total
      }
    }
  }
}
    ${ProjectBaseFragmentDoc}`;
export function useMyProjectsQuery(baseOptions: Apollo.QueryHookOptions<MyProjectsQuery, MyProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyProjectsQuery, MyProjectsQueryVariables>(MyProjectsDocument, options);
      }
export function useMyProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyProjectsQuery, MyProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyProjectsQuery, MyProjectsQueryVariables>(MyProjectsDocument, options);
        }
export type MyProjectsQueryHookResult = ReturnType<typeof useMyProjectsQuery>;
export type MyProjectsLazyQueryHookResult = ReturnType<typeof useMyProjectsLazyQuery>;
export type MyProjectsQueryResult = Apollo.QueryResult<MyProjectsQuery, MyProjectsQueryVariables>;
 const ScreensDocument = gql`
    query screens($project: ID!) {
  screens(
    filters: {project: {id: {eq: $project}}, deletedAt: null}
    pagination: {pageSize: 1000}
  ) {
    data {
      ...Screen
    }
    meta {
      pagination {
        page
        pageSize
        pageCount
        total
      }
    }
  }
}
    ${ScreenFragmentDoc}`;
export function useScreensQuery(baseOptions: Apollo.QueryHookOptions<ScreensQuery, ScreensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScreensQuery, ScreensQueryVariables>(ScreensDocument, options);
      }
export function useScreensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScreensQuery, ScreensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScreensQuery, ScreensQueryVariables>(ScreensDocument, options);
        }
export type ScreensQueryHookResult = ReturnType<typeof useScreensQuery>;
export type ScreensLazyQueryHookResult = ReturnType<typeof useScreensLazyQuery>;
export type ScreensQueryResult = Apollo.QueryResult<ScreensQuery, ScreensQueryVariables>;
 const TeamsDocument = gql`
    query teams {
  tenantTeams(pagination: {pageSize: 1000}) {
    data {
      ...TenantTeam
    }
  }
}
    ${TenantTeamFragmentDoc}`;
export function useTeamsQuery(baseOptions?: Apollo.QueryHookOptions<TeamsQuery, TeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamsQuery, TeamsQueryVariables>(TeamsDocument, options);
      }
export function useTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamsQuery, TeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamsQuery, TeamsQueryVariables>(TeamsDocument, options);
        }
export type TeamsQueryHookResult = ReturnType<typeof useTeamsQuery>;
export type TeamsLazyQueryHookResult = ReturnType<typeof useTeamsLazyQuery>;
export type TeamsQueryResult = Apollo.QueryResult<TeamsQuery, TeamsQueryVariables>;
 const MyTenantDocument = gql`
    query myTenant($id: ID) {
  tenant(id: $id) {
    data {
      ...Tenant
    }
  }
}
    ${TenantFragmentDoc}`;
export function useMyTenantQuery(baseOptions?: Apollo.QueryHookOptions<MyTenantQuery, MyTenantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyTenantQuery, MyTenantQueryVariables>(MyTenantDocument, options);
      }
export function useMyTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyTenantQuery, MyTenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyTenantQuery, MyTenantQueryVariables>(MyTenantDocument, options);
        }
export type MyTenantQueryHookResult = ReturnType<typeof useMyTenantQuery>;
export type MyTenantLazyQueryHookResult = ReturnType<typeof useMyTenantLazyQuery>;
export type MyTenantQueryResult = Apollo.QueryResult<MyTenantQuery, MyTenantQueryVariables>;
 const AvailableTenantsToJoinDocument = gql`
    query availableTenantsToJoin {
  availableTenantsToJoin {
    id
    attributes {
      slug
      companyName
      domain
    }
  }
}
    `;
export function useAvailableTenantsToJoinQuery(baseOptions?: Apollo.QueryHookOptions<AvailableTenantsToJoinQuery, AvailableTenantsToJoinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableTenantsToJoinQuery, AvailableTenantsToJoinQueryVariables>(AvailableTenantsToJoinDocument, options);
      }
export function useAvailableTenantsToJoinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableTenantsToJoinQuery, AvailableTenantsToJoinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableTenantsToJoinQuery, AvailableTenantsToJoinQueryVariables>(AvailableTenantsToJoinDocument, options);
        }
export type AvailableTenantsToJoinQueryHookResult = ReturnType<typeof useAvailableTenantsToJoinQuery>;
export type AvailableTenantsToJoinLazyQueryHookResult = ReturnType<typeof useAvailableTenantsToJoinLazyQuery>;
export type AvailableTenantsToJoinQueryResult = Apollo.QueryResult<AvailableTenantsToJoinQuery, AvailableTenantsToJoinQueryVariables>;